import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { BasicLearningSpace } from "types";

const initialState = {
  id: "",
};

const learningSpace = createSlice({
  name: "learningSpace",
  initialState,
  reducers: {
    setLearningSpace(state, action: PayloadAction<BasicLearningSpace>) {
      state.id = action.payload.id;
    },
  },
});

export const { setLearningSpace } = learningSpace.actions;
export default learningSpace.reducer;
