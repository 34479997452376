import retailRevenueByDay from "./slices/retailRevenueByDay";
import dateRange from "./slices/dateRange";
import learningSpace from "./slices/learningSpace";
/*
Register reducer here!
*/
const reducers = {
  retailRevenueByDay,
  dateRange,
  learningSpace,
};

export default reducers;
