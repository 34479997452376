import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { endOfMonth, startOfMonth } from "date-fns";
import { BasicDateRangeDispatch } from "views/Dashboard/Dashboard";

const today = new Date();

const initialState = {
  startDate: startOfMonth(today).toISOString(),
  endDate: endOfMonth(today).toISOString(),
  key: "statistics-date-filter",
};

const dateRange = createSlice({
  name: "date",
  initialState,
  reducers: {
    setDateRange(state, action: PayloadAction<BasicDateRangeDispatch>) {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
  },
});

export const { setDateRange } = dateRange.actions;
export default dateRange.reducer;
