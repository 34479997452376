import { AppBar, Box, Toolbar } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import BasicUserMenu from "widgets/BasicUserMenu";
import images from "assets/images";

interface IProps {
  children: any;
}

const DefaultLayout: React.FC<IProps> = ({ children }) => {
  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent="space-between"
            alignItems="center"
          >
            <div>
              <img
                style={{
                  maxWidth: 150,
                }}
                src={images.LOGO}
                alt="agilearn logo"
              />
            </div>
            <div>
              <BasicUserMenu />
            </div>
          </Box>
        </Toolbar>
      </AppBar>

      <Container maxWidth="xl" className="main">
        {children}
      </Container>
    </Box>
  );
};

export default DefaultLayout;
