import React, { Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider, FirestoreProvider, useFirebaseApp } from "reactfire";
import { getFirestore } from "firebase/firestore";
import ErrorBoundary from "components/ErrorBoundary";
import NeedRole from "./widgets/NeedRole";
import { getAuth } from "firebase/auth";
import { Login, Dashboard } from "views";
import { CircularProgress } from "@mui/material";
import DefaultLayout from "layouts/Default";

function App() {
  const firestoreInstance = getFirestore(useFirebaseApp());
  const authInstance = getAuth(useFirebaseApp());

  return (
    <div className="App">
      <ErrorBoundary>
        <AuthProvider sdk={authInstance}>
          <FirestoreProvider sdk={firestoreInstance}>
            <DefaultLayout>
              <Suspense fallback={<CircularProgress />}>
                <Router>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <NeedRole any={["dashboard:admin", "admin"]}>
                          <Dashboard />
                        </NeedRole>
                      }
                    />
                    <Route path="/login" element={<Login />} />
                  </Routes>
                </Router>
              </Suspense>
            </DefaultLayout>
          </FirestoreProvider>
        </AuthProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
