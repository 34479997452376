import { Avatar, Button, Menu, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useState } from "react";
import { useAuth, useUser } from "reactfire";

interface IProps {}

const BasicUserMenu: React.FC<IProps> = () => {
  const { data: user } = useUser({ suspense: true });
  const auth = useAuth();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [loggingOut, setLoggingOut] = useState(false);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = useCallback(async () => {
    try {
      if (!!user) {
        setLoggingOut(true);

        await auth.signOut();
      }
    } catch (e) {
      console.error((e as any).message);
      setLoggingOut(false);

      alert("Đã có lỗi xảy ra!");
    }
  }, [user, auth]);

  if (!user) return <></>;

  return (
    <>
      <Button
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <Avatar
          src={user.photoURL || ""}
          alt={user.displayName || user.email || ""}
        />
        <Box ml={1}>
          <Typography>{user.displayName}</Typography>
        </Box>
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled={loggingOut} onClick={handleSignout}>
          {loggingOut ? "Đang đăng xuất..." : "Đăng xuất"}
        </MenuItem>
      </Menu>
    </>
  );
};

export default BasicUserMenu;
