import { createTheme } from "@mui/material";

const lightTheme = createTheme({
  typography: {
    fontFamily: "Quicksand Roboto",
    fontSize: 15,
  },
  palette: {
    primary: {
      main: "#483392",
    },
    secondary: {
      main: "#F37029",
    },
    background: {
      default: "#fff",
      paper: "#e9e9e9",
    },
    text: {
      secondary: "#2E3A59",
    },
  },
  shape: {
    borderRadius: 5,
  },
  spacing: 10,
});

export default lightTheme;
