import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { User } from 'firebase/auth'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useIdTokenResult, useUser } from 'reactfire'
import { SystemRoles } from 'types'

interface IProps {
    any: SystemRoles[],
    children: any
}

export const hasAnyRole = (roles: {[k: string]: boolean}, requireRoles: SystemRoles[]) => {
    let hasRole = false

    for (let i = 0; i < requireRoles.length; i++) {
        if (!!roles[requireRoles[i]]) {
            hasRole = true
            break
        }
    }

    return hasRole
}

const CheckRole: React.FC<{user: User, children: any, any: SystemRoles[]}> = ({user, children, any}) => {

    const {data: idToken} = useIdTokenResult(user, false, {suspense: true})

    if (!hasAnyRole(idToken.claims, any)) {
        return <Box mt={5}>
            <Typography variant="h6">Xin lỗi, bạn không có quyền để thực hiện tác vụ này!</Typography>
        </Box>
    }

    return <>{children}</>
}

const NeedRole: React.FC<IProps> = ({any, children}) => {
    const { data: user } = useUser({suspense: true})

    const navigate = useNavigate()

    if (!user) {
        navigate('/login')
        return <></>
    }

    return <CheckRole user={user} any={any}>{children}</CheckRole>
}

export default NeedRole