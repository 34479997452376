import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RetailStatisticsByDay } from "types";

const initialState = [] as RetailStatisticsByDay[];

const retailRevenueByDay = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setRetailRevenueByDay(
      state,
      action: PayloadAction<RetailStatisticsByDay[]>
    ) {
      return action.payload.map((data) => {
        if (typeof data.timeTracks.statsAt !== "string") {
          data.timeTracks.statsAt = data.timeTracks.statsAt.toDate().toString();
        }

        return data;
      });
    },
  },
});

export const { setRetailRevenueByDay } = retailRevenueByDay.actions;
export default retailRevenueByDay.reducer;
